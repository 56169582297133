.styles_Accordion__KwSA9{width:100%;overflow:hidden}.styles_titleWrapper__Dymey{width:100%;display:flex;justify-content:space-between}.styles_link__XX6D2{text-transform:uppercase;text-align:left}.styles_subPane__oHRT9{height:0}.styles_arrow__4JQsY{transform-origin:45% 45%;transition:transform 0.2s ease-in-out}.styles_arrow__4JQsY.styles_isOpen__O_DQu{transform:rotate(90deg)}.styles_dark__Eb6Jl{background-color:red}.styles_dark__Eb6Jl .styles_arrow__4JQsY{background-color:blue}.styles_dark__Eb6Jl .styles_arrow__4JQsY path{stroke:#000}
.styles_AccordionPanel__6ymdv{overflow:hidden}.styles_head__MXbBw{width:100%;display:flex;justify-content:space-between;cursor:pointer;-webkit-tap-highlight-color:rgba(0,0,0,0)}.styles_arrowWrapper__VoaFp{flex:1 1;text-align:right}.styles_arrow__Bf_tK{transform-origin:45% 45%;transition:transform 0.2s ease-in-out}.styles_arrow__Bf_tK.styles_isOpen__dB7fA{transform:rotate(90deg)}.styles_arrow__Bf_tK.styles_dark__di5jK{transform:rotate(-180deg)}.styles_arrow__Bf_tK.styles_dark__di5jK.styles_isOpen__dB7fA{transform:rotate(0)}.styles_body__L8uUU{height:0;overflow:hidden}
.styles_CtaWithPrice__ESqvC{width:100%;color:#fff;padding:0px;position:relative;height:56px;min-height:56px;border-radius:2px;padding-bottom:2px;background-color:#191b4a;transition:border-radius 0.2s ease-in-out, width 0.2s ease-in-out, background-color 0.2s ease-in-out}@media screen and (min-width: 768px){.styles_CtaWithPrice__ESqvC:hover{background-color:#333796}}.styles_CtaWithPrice__ESqvC.styles_isLoading__21gUH{cursor:not-allowed;pointer-events:none}@media screen and (min-width: 768px){.styles_CtaWithPrice__ESqvC.styles_isLoading__21gUH:hover{background-color:#191b4a}}.styles_CtaWithPrice__ESqvC.styles_snow-drift__YmyhP{background-color:#f5f5f5;color:#000}@media screen and (min-width: 768px){.styles_CtaWithPrice__ESqvC.styles_snow-drift__YmyhP:hover{background-color:#f2f0ea}}.styles_CtaWithPrice__ESqvC.styles_disabled__I1aQu{cursor:not-allowed;color:gray;background-color:#f2f2f2}.styles_CtaWithPrice__ESqvC .styles_spinnerContainer__3747e{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}.styles_CtaWithPrice__ESqvC .styles_spinnerContainer__3747e .styles_spinner__5jnH4{width:22px}.styles_CtaWithPrice__ESqvC .styles_buttonLabel__qxo8R{display:inline-flex;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;align-items:center}.styles_CtaWithPrice__ESqvC .styles_price__89H3R{background:#fff;background:rgba(255,255,255,.15);margin-left:8px;line-height:.5;letter-spacing:.05em;padding:5px 3px 5px 3px;border-radius:2px}.styles_CtaWithPrice__ESqvC .styles_content__pk0tV{position:relative}.styles_CtaWithPrice__ESqvC.styles_isFullscreen__8QT5Z:first-child,.styles_CtaWithPrice__ESqvC.styles_isFullscreen__8QT5Z:last-child{border-radius:0}
.styles_SliderControls__PcArE{display:flex;height:8px}.styles_SliderControls__PcArE .styles_button__yryDZ{position:relative;flex-shrink:0;box-sizing:border-box;margin-right:10px;cursor:pointer;width:8px;height:8px;border-radius:50%;background-color:rgba(0,0,0,0);box-sizing:border-box}.styles_SliderControls__PcArE .styles_button__yryDZ:last-child{margin-right:0}.styles_SliderControls__PcArE .styles_button__yryDZ:before,.styles_SliderControls__PcArE .styles_button__yryDZ:after{content:"";position:absolute;top:50%;left:50%;border-radius:50%;box-sizing:border-box}.styles_SliderControls__PcArE .styles_button__yryDZ:before{transform:translate(-50%, -50%);background-color:currentColor;width:100%;height:100%;opacity:.3}.styles_SliderControls__PcArE .styles_button__yryDZ:after{display:none;transform:translate(-50%, -50%);width:100%;height:100%;background-color:currentColor}.styles_SliderControls__PcArE .styles_button__yryDZ.styles_active__LBh1B:before{display:none}.styles_SliderControls__PcArE .styles_button__yryDZ.styles_active__LBh1B:after{display:block}
.styles_cta__NkO3K{margin-left:auto}@media screen and (min-width: 768px){.styles_cta__NkO3K{margin-left:20px}}.styles_cta__NkO3K.styles_isLoading__rRALS{pointer-events:none}
.styles_SliderArrow__R52cl{width:50px;height:50px;padding:0;cursor:pointer}.styles_SliderArrow__R52cl .styles_arrowIcon__ZTrFa{display:block}.styles_SliderArrow__R52cl .styles_arrowIcon__ZTrFa.styles_black__PdWyO path{stroke:#fff}.styles_SliderArrow__R52cl .styles_arrowIcon__ZTrFa path{stroke:#000}.styles_SliderArrow__R52cl.styles_black__PdWyO .styles_arrowIcon__ZTrFa path{stroke:#fff}
.styles_TagPreOrder__HL4sb{display:inline-flex;align-items:center}.styles_TagPreOrder__HL4sb .styles_label__RPq5V{display:flex;align-items:center}
button.styles_sliderArrow__617rQ{position:absolute;top:50%}button.styles_sliderArrow__617rQ.styles_hide__CkooM{display:none}button.styles_sliderArrow__617rQ.styles_layout-s__92LUp{width:30px;height:30px}button.styles_sliderArrow__617rQ.styles_layout-s__92LUp svg{width:30px}button.styles_sliderArrow__617rQ.styles_layout-xs__SRpqs{width:30px;height:30px}button.styles_sliderArrow__617rQ.styles_layout-xs__SRpqs svg{width:24px}@media screen and (min-width: 768px){button.styles_sliderArrow__617rQ{display:flex}}.styles_leftArrow__zkoVF{left:0;transform:translate(-20%, -50%) rotate(180deg)}.styles_leftArrow__zkoVF.styles_inside__HdbO7{transform:translate(20%, -50%) rotate(180deg)}.styles_rightArrow__tvDAS{right:0;transform:translate(20%, -50%) rotate(0deg)}.styles_rightArrow__tvDAS.styles_inside__HdbO7{transform:translate(-20%, -50%) rotate(0deg)}
.styles_CardProduct__eYcg6{position:relative;border-radius:2px;overflow:hidden;display:flex;flex-direction:column;border-radius:0}.styles_CardProduct__eYcg6 .styles_cardContainer__Ot_GV{position:relative;display:flex;flex-direction:column;height:100%}.styles_CardProduct__eYcg6 .styles_cardMediaWrapper__rLUKm{cursor:pointer}.styles_CardProduct__eYcg6 .styles_cardLink__a_6k7{position:relative;display:flex;flex-direction:column;justify-content:center;flex:1 1}.styles_CardProduct__eYcg6 .styles_compareAtPrice__1hKBz{display:inline-block;vertical-align:middle;margin-right:10px;color:#bc2f26}.styles_CardProduct__eYcg6 .styles_cardTop__xYXvR{display:flex;align-items:center;position:absolute;left:0;right:0;z-index:2;flex-direction:row;top:0;padding:10px;padding:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          0
      ) + (clamp(16px, 4vw, 32px) * max(0, (-1)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 1)
  )}@media screen and (min-width: 1940px){.styles_CardProduct__eYcg6 .styles_cardTop__xYXvR{padding:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 0
      ) + (clamp(16px, 4vw, 32px) * max(0, (0 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 1)
    )}}.styles_CardProduct__eYcg6 .styles_cardTop__xYXvR .styles_cardRight__dUEcg{display:flex;flex-direction:column;align-items:center;padding-left:0;flex:1 1;justify-content:flex-end}@media screen and (min-width: 768px){.styles_CardProduct__eYcg6 .styles_cardTop__xYXvR .styles_cardRight__dUEcg{flex-flow:row wrap;justify-content:flex-start}}.styles_CardProduct__eYcg6 .styles_label__ck9sn{margin-bottom:4px;margin-right:4px}.styles_CardProduct__eYcg6 .styles_cardImages__UJqav{box-sizing:border-box;position:relative;overflow:hidden;border-radius:2px}.styles_CardProduct__eYcg6 .styles_addToCart__JUOPO{padding:10px;border-top-left-radius:0;border-top-right-radius:0;box-sizing:border-box;top:0px}.styles_CardProduct__eYcg6 .styles_hoverImage__doKX_{position:absolute;z-index:1;top:0;left:0;width:100%;height:100%}.styles_CardProduct__eYcg6 .styles_cardBottom__AVSac{position:relative;display:flex;align-items:center;flex-direction:column;text-align:center;height:100%;bottom:0;left:0;right:0;border-radius:0;padding:0 8px;margin-top:12px}@media screen and (min-width: 768px){.styles_CardProduct__eYcg6 .styles_cardBottom__AVSac{margin-top:16px}}.styles_CardProduct__eYcg6 .styles_cardBottom__AVSac .styles_cardRight__dUEcg{padding-top:4px;display:flex;flex-direction:column;align-items:center;padding-left:0;flex-flow:row wrap}.styles_CardProduct__eYcg6 .styles_cardBottom__AVSac .styles_cardLeft__8SU5y{text-align:center}.styles_CardProduct__eYcg6 .styles_cardBottom__AVSac{margin-top:20px}@media screen and (min-width: 768px){.styles_CardProduct__eYcg6 .styles_cardBottom__AVSac{margin-top:20px;border-radius:2px;transition:background 0.2s ease-in-out}}.styles_CardProduct__eYcg6 .styles_cta__4AenG{margin-top:8px}.styles_CardProduct__eYcg6 .styles_cta__4AenG+.styles_cardBottom__AVSac{margin-top:6px}.styles_CardProduct__eYcg6.styles_isFullWidth__p1vO8{border-radius:0}
.styles_SliderProducts__Saxb7{max-width:100%;overflow:hidden}@media screen and (min-width: 768px){.styles_SliderProducts__Saxb7{overflow:visible}}.styles_SliderProducts__Saxb7 .styles_controls__FQx0H{display:flex;justify-content:center}.styles_SliderProducts__Saxb7 .styles_controls__FQx0H .styles_sliderControls__4qPIq{margin-top:28px}.styles_SliderProducts__Saxb7 .styles_sliderContainer__jsn_D{position:relative;width:100%}@media screen and (min-width: 768px){.styles_SliderProducts__Saxb7 .styles_sliderContainer__jsn_D{max-width:1940px;margin-left:auto;margin-right:auto}}.styles_SliderProducts__Saxb7 .styles_slider__JUAdR{position:relative;width:100%;box-sizing:border-box}.styles_SliderProducts__Saxb7 .styles_sliderItem__6JUvv{position:relative}.styles_SliderProducts__Saxb7.styles_s__bPV7A .styles_slider__JUAdR{margin-left:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          0
      ) + (clamp(16px, 4vw, 32px) * max(0, (-1)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 1)
  )}@media screen and (min-width: 1940px){.styles_SliderProducts__Saxb7.styles_s__bPV7A .styles_slider__JUAdR{margin-left:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 0
      ) + (clamp(16px, 4vw, 32px) * max(0, (0 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 1)
    )}}@media screen and (min-width: 768px){.styles_SliderProducts__Saxb7.styles_s__bPV7A .styles_slider__JUAdR{margin-left:0}}.styles_SliderProducts__Saxb7.styles_s__bPV7A .styles_sliderItem__6JUvv{width:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          3
      ) + (clamp(16px, 4vw, 32px) * max(0, (2)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 0)
  );margin-left:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          0
      ) + (clamp(16px, 4vw, 32px) * max(0, (-1)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 1)
  )}@media screen and (min-width: 1940px){.styles_SliderProducts__Saxb7.styles_s__bPV7A .styles_sliderItem__6JUvv{width:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 3
      ) + (clamp(16px, 4vw, 32px) * max(0, (3 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 0)
    )}}@media screen and (min-width: 1940px){.styles_SliderProducts__Saxb7.styles_s__bPV7A .styles_sliderItem__6JUvv{margin-left:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 0
      ) + (clamp(16px, 4vw, 32px) * max(0, (0 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 1)
    )}}@media screen and (min-width: 930px){.styles_SliderProducts__Saxb7.styles_s__bPV7A .styles_sliderItem__6JUvv{width:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (24px * 2) - ((
            12 - 1
          ) * 24px)
  ) / 12 *
          2
      ) + (24px * max(0, (1)))) + (
        24px * 0
      ) + (24px * 0)
  );margin-left:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (24px * 2) - ((
            12 - 1
          ) * 24px)
  ) / 12 *
          0
      ) + (24px * max(0, (-1)))) + (
        24px * 0
      ) + (24px * 1)
  )}}@media screen and (min-width: 930px)and (min-width: 1940px){.styles_SliderProducts__Saxb7.styles_s__bPV7A .styles_sliderItem__6JUvv{width:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (24px * 2) - ((12 - 1) * 24px)) / 12 * 2
      ) + (24px * max(0, (2 - 1)))) + (
          24px * 0
        ) + (24px * 0)
    )}}@media screen and (min-width: 930px)and (min-width: 1940px){.styles_SliderProducts__Saxb7.styles_s__bPV7A .styles_sliderItem__6JUvv{margin-left:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (24px * 2) - ((12 - 1) * 24px)) / 12 * 0
      ) + (24px * max(0, (0 - 1)))) + (
          24px * 0
        ) + (24px * 1)
    )}}@media screen and (min-width: 768px){.styles_SliderProducts__Saxb7.styles_xs__SBQaH .styles_slider__JUAdR{margin-left:0}}.styles_SliderProducts__Saxb7.styles_xs__SBQaH .styles_sliderItem__6JUvv{width:165px;margin-left:16px}@media screen and (min-width: 1024px){.styles_SliderProducts__Saxb7.styles_xs__SBQaH .styles_sliderItem__6JUvv{width:180px}}.styles_SliderProducts__Saxb7.styles_m__Kz9r_ .styles_slider__JUAdR{margin-left:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          0
      ) + (clamp(16px, 4vw, 32px) * max(0, (-1)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 1)
  )}@media screen and (min-width: 1940px){.styles_SliderProducts__Saxb7.styles_m__Kz9r_ .styles_slider__JUAdR{margin-left:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 0
      ) + (clamp(16px, 4vw, 32px) * max(0, (0 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 1)
    )}}@media screen and (min-width: 768px){.styles_SliderProducts__Saxb7.styles_m__Kz9r_ .styles_slider__JUAdR{margin-left:0}}.styles_SliderProducts__Saxb7.styles_m__Kz9r_ .styles_sliderItem__6JUvv{width:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          5.5
      ) + (clamp(16px, 4vw, 32px) * max(0, (4.5)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 0)
  );margin-left:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          0
      ) + (clamp(16px, 4vw, 32px) * max(0, (-1)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 1)
  )}@media screen and (min-width: 1940px){.styles_SliderProducts__Saxb7.styles_m__Kz9r_ .styles_sliderItem__6JUvv{width:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 5.5
      ) + (clamp(16px, 4vw, 32px) * max(0, (5.5 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 0)
    )}}@media screen and (min-width: 1940px){.styles_SliderProducts__Saxb7.styles_m__Kz9r_ .styles_sliderItem__6JUvv{margin-left:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 0
      ) + (clamp(16px, 4vw, 32px) * max(0, (0 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 1)
    )}}@media screen and (min-width: 768px){.styles_SliderProducts__Saxb7.styles_m__Kz9r_ .styles_sliderItem__6JUvv{width:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (24px * 2) - ((
            12 - 1
          ) * 24px)
  ) / 12 *
          3
      ) + (24px * max(0, (2)))) + (
        24px * 0
      ) + (24px * 0)
  );margin-left:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (24px * 2) - ((
            12 - 1
          ) * 24px)
  ) / 12 *
          0
      ) + (24px * max(0, (-1)))) + (
        24px * 0
      ) + (24px * 1)
  )}}@media screen and (min-width: 768px)and (min-width: 1940px){.styles_SliderProducts__Saxb7.styles_m__Kz9r_ .styles_sliderItem__6JUvv{width:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (24px * 2) - ((12 - 1) * 24px)) / 12 * 3
      ) + (24px * max(0, (3 - 1)))) + (
          24px * 0
        ) + (24px * 0)
    )}}@media screen and (min-width: 768px)and (min-width: 1940px){.styles_SliderProducts__Saxb7.styles_m__Kz9r_ .styles_sliderItem__6JUvv{margin-left:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (24px * 2) - ((12 - 1) * 24px)) / 12 * 0
      ) + (24px * max(0, (0 - 1)))) + (
          24px * 0
        ) + (24px * 1)
    )}}.styles_SliderProducts__Saxb7.styles_xxs__NFD5k .styles_slider__JUAdR{margin-left:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          0
      ) + (clamp(16px, 4vw, 32px) * max(0, (-1)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 0)
  )}@media screen and (min-width: 1940px){.styles_SliderProducts__Saxb7.styles_xxs__NFD5k .styles_slider__JUAdR{margin-left:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 0
      ) + (clamp(16px, 4vw, 32px) * max(0, (0 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 0)
    )}}@media screen and (min-width: 768px){.styles_SliderProducts__Saxb7.styles_xxs__NFD5k .styles_slider__JUAdR{margin-left:0}}.styles_SliderProducts__Saxb7.styles_xxs__NFD5k .styles_sliderItem__6JUvv{width:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          3
      ) + (clamp(16px, 4vw, 32px) * max(0, (2)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 0)
  );margin-left:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (clamp(16px, 4vw, 32px) * 2) - ((
            6 - 1
          ) * clamp(16px, 4vw, 32px))
  ) / 6 *
          0
      ) + (clamp(16px, 4vw, 32px) * max(0, (-1)))) + (
        clamp(16px, 4vw, 32px) * 0
      ) + (clamp(16px, 4vw, 32px) * 1)
  )}@media screen and (min-width: 1940px){.styles_SliderProducts__Saxb7.styles_xxs__NFD5k .styles_sliderItem__6JUvv{width:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 3
      ) + (clamp(16px, 4vw, 32px) * max(0, (3 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 0)
    )}}@media screen and (min-width: 1940px){.styles_SliderProducts__Saxb7.styles_xxs__NFD5k .styles_sliderItem__6JUvv{margin-left:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (clamp(16px, 4vw, 32px) * 2) - ((6 - 1) * clamp(16px, 4vw, 32px))) / 6 * 0
      ) + (clamp(16px, 4vw, 32px) * max(0, (0 - 1)))) + (
          clamp(16px, 4vw, 32px) * 0
        ) + (clamp(16px, 4vw, 32px) * 1)
    )}}@media screen and (min-width: 768px){.styles_SliderProducts__Saxb7.styles_xxs__NFD5k .styles_sliderItem__6JUvv{width:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (24px * 2) - ((
            12 - 1
          ) * 24px)
  ) / 12 *
          2
      ) + (24px * max(0, (1)))) + (
        24px * 0
      ) + (24px * 0)
  );margin-left:calc(
    calc((
        calc(
    calc(100vw - var(--scroll-bar)) - (24px * 2) - ((
            12 - 1
          ) * 24px)
  ) / 12 *
          0
      ) + (24px * max(0, (-1)))) + (
        24px * 0
      ) + (24px * 1)
  )}}@media screen and (min-width: 768px)and (min-width: 1940px){.styles_SliderProducts__Saxb7.styles_xxs__NFD5k .styles_sliderItem__6JUvv{width:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (24px * 2) - ((12 - 1) * 24px)) / 12 * 2
      ) + (24px * max(0, (2 - 1)))) + (
          24px * 0
        ) + (24px * 0)
    )}}@media screen and (min-width: 768px)and (min-width: 1940px){.styles_SliderProducts__Saxb7.styles_xxs__NFD5k .styles_sliderItem__6JUvv{margin-left:calc(
      calc((
        calc((1940px - var(--scroll-bar-grid)) - (24px * 2) - ((12 - 1) * 24px)) / 12 * 0
      ) + (24px * max(0, (0 - 1)))) + (
          24px * 0
        ) + (24px * 1)
    )}}
